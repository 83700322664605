<template>
  <AppCard>
    <template #header>
      <XeStepperCardHeader analytics-name="xe-send-money-verify-identity-ekyc" />
    </template>

    <AppCardImageTitle
      :src="require('@galileo/assets/images/illustrations/man-detective.svg')"
      :title="$t('PageSendMoneyVerifyIdentityEKYC.Title').value"
      alt="Identity"
    >
      <p>
        {{ $t('PageSendMoneyVerifyIdentityEKYC.Description').value }}
      </p>
    </AppCardImageTitle>

    <template #footer>
      <AppCardFooter>
        <AppButton analytics-name="send-verify-identity-ekyc-continue" @click="verify">{{
          $t('PageSendMoneyVerifyIdentityEKYC.ButtonContinue').value
        }}</AppButton>
      </AppCardFooter>
    </template>
  </AppCard>
</template>

<script>
import { onBeforeMount } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'

import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'

import {
  SEGMENT_EVENTS,
  SEGMENT_IDENTITY_VERIFICATION_PLATFORMS,
} from '@galileo/constants/segmentAnalytics'

import {
  AppButton,
  AppCard,
  AppCardFooter,
  AppCardImageTitle,
  useMediaQuery,
} from '@oen.web.vue2/ui'

import { useI18nStore, useSendMoneyStore, useAnalyticsStore } from '@galileo/stores'

export default {
  name: 'SendMoneyVerifyIdentityEKYC',
  components: {
    AppCardImageTitle,
    AppButton,
    AppCard,
    XeStepperCardHeader,
    AppCardFooter,
  },
  setup() {
    const router = useRouter()
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()

    const verify = () => {
      router.replace({ name: 'SendMoneyIdology' })
      onBeforeMount(async () => {
        useSendMoneyStore().setActiveStep('/send-money/summary/verification')
      })
    }
    return {
      mq: useMediaQuery(),
      verify,
      $t,
    }
  },
}
</script>

<style scoped></style>
